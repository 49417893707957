/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    h3: "h3",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Introduction"), "\n", React.createElement(_components.p, null, "Sometimes in a given project, we might want to convert a color from one format to another. Here is a  little reference of some common javascript color transformations."), "\n", React.createElement(_components.h3, null, "Hex to RGB"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "function hexToRGB(h) {\n  let r = \"0\", g = \"0\", b = \"0\";\n  if (hex.length == 4) {\n    r = \"0x\" + hex[1] + hex[1];\n    g = \"0x\" + hex[2] + hex[2];\n    b = \"0x\" + hex[3] + hex[3];\n  } else if (hex.length == 7) {\n    r = \"0x\" + hex[1] + hex[2];\n    g = \"0x\" + hex[3] + hex[4];\n    b = \"0x\" + hex[5] + hex[6];\n  }\n  return \"rgb(\"+ +r + \",\" + +g + \",\" + +b + \")\";\n}\n  \n")), "\n", React.createElement(_components.h3, null, "RGB to Hex"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "function RGBToHex(r,g,b) {\n  r = r.toString(16);\n  g = g.toString(16);\n  b = b.toString(16);\n\n  if (r.length == 1)\n    r = \"0\" + r;\n  if (g.length == 1)\n    g = \"0\" + g;\n  if (b.length == 1)\n    b = \"0\" + b;\n\n  return \"#\" + r + g + b;\n}\n")), "\n", React.createElement(_components.h3, null, "RGB to HSL"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "function RGBToHSL(r,g,b) {\n   // Then to HSL\n  const rInt = parseInt(r, 16) / 255;\n  const gInt = parseInt(g, 16) / 255;\n  const bInt = parseInt(b, 16) / 255;\n  let cmin = Math.min(rInt,gInt,bInt),\n      cmax = Math.max(rInt,gInt,bInt),\n      delta = cmax - cmin,\n      h = 0,\n      s = 0,\n      l = 0;\n\n  if (delta == 0)\n    h = 0;\n  else if (cmax == rInt)\n    h = ((gInt - bInt) / delta) % 6;\n  else if (cmax == gInt)\n    h = (bInt - rInt) / delta + 2;\n  else\n    h = (rInt - gInt) / delta + 4;\n\n  h = Math.round(h * 60);\n\n  if (h < 0)\n    h += 360;\n\n  l = (cmax + cmin) / 2;\n  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));\n  s = +(s * 100).toFixed(1);\n  l = +(l * 100).toFixed(1);\n\n  return \"hsl(\" + h + \",\" + s + \"%,\" + l + \"%)\";\n}\n")), "\n", React.createElement(_components.h3, null, "HEX with alpha to RGBA"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "function hexAToRGBA(h) {\n  let r = 0, g = 0, b = 0, a = 1;\n\n  if (h.length == 5) {\n    r = \"0x\" + h[1] + h[1];\n    g = \"0x\" + h[2] + h[2];\n    b = \"0x\" + h[3] + h[3];\n    a = \"0x\" + h[4] + h[4];\n\n  } else if (h.length == 9) {\n    r = \"0x\" + h[1] + h[2];\n    g = \"0x\" + h[3] + h[4];\n    b = \"0x\" + h[5] + h[6];\n    a = \"0x\" + h[7] + h[8];\n  }\n  a = +(a / 255).toFixed(3);\n\n  return \"rgba(\" + +r + \",\" + +g + \",\" + +b + \",\" + a + \")\";\n}\n")), "\n", React.createElement(_components.h3, null, "Hex to HSL"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "export const hexToHSL = (hex: string) => {\n  // Convert hex to RGB first\n  let r = \"0\", g = \"0\", b = \"0\";\n  if (hex.length == 4) {\n    r = \"0x\" + hex[1] + hex[1];\n    g = \"0x\" + hex[2] + hex[2];\n    b = \"0x\" + hex[3] + hex[3];\n  } else if (hex.length == 7) {\n    r = \"0x\" + hex[1] + hex[2];\n    g = \"0x\" + hex[3] + hex[4];\n    b = \"0x\" + hex[5] + hex[6];\n  }\n  // Then to HSL\n  const rInt = parseInt(r, 16) / 255;\n  const gInt = parseInt(g, 16) / 255;\n  const bInt = parseInt(b, 16) / 255;\n  let cmin = Math.min(rInt,gInt,bInt),\n      cmax = Math.max(rInt,gInt,bInt),\n      delta = cmax - cmin,\n      h = 0,\n      s = 0,\n      l = 0;\n\n  if (delta == 0)\n    h = 0;\n  else if (cmax == rInt)\n    h = ((gInt - bInt) / delta) % 6;\n  else if (cmax == gInt)\n    h = (bInt - rInt) / delta + 2;\n  else\n    h = (rInt - gInt) / delta + 4;\n\n  h = Math.round(h * 60);\n\n  if (h < 0)\n    h += 360;\n\n  l = (cmax + cmin) / 2;\n  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));\n  s = +(s * 100).toFixed(1);\n  l = +(l * 100).toFixed(1);\n\n  return \"hsl(\" + h + \",\" + s + \"%,\" + l + \"%)\";\n}\n\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
